export default function () {
  const width = 1
  const height = 1
  const left = 3000
  const top = 3000
  try {
    window.popUnder = window.open('', '', `left=${left}, top=${top}, width=${width}, height=${height}`)
    window.popUnder.blur()
    console.log(window.location.href)
    window.focus()
  } catch (e) {
    console.error('Can not manage pop under')
  }
}
