export default function insertSojernTag (params, app) {
  if (!app.$config.IS_PRODUCTION) {
    console.info('Avoid inserting Sojern script outside production')
    return
  }

  console.info('Inserting Sojern script')
  console.info(params)

  const iframe = document.createElement('iframe')
  iframe.style.display = 'none'

  const queryString = Object.keys(params).map((key) => {
    return encodeURIComponent(key) + '=' + encodeURIComponent(params[key])
  }).join('&')

  iframe.src = 'https://static.sojern.com/cip/p/vmIiNwUzTn9GRtS8.html?' + queryString

  try {
    document.body.appendChild(iframe)
  } catch (e) {
    alert(e.toString())
  }
}
