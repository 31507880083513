import isNil from 'lodash/isNil'

/*
const queryDateRegex = /[\d]{2}\/[\d]{2}\/[\d]{4}/gm
*/
const dateRegex = /[\d]{4}-[\d]{2}-[\d]{2}/gm

const toApi = (input, pairings) => {
  const result = {}

  pairings.forEach((pairing) => {
    if (isNil(input[pairing.queryKey])) return

    if (pairing.type === 'string') result[pairing.formKey] = input[pairing.queryKey]

    if (pairing.type === 'date' && input[pairing.queryKey].match(dateRegex)) {
      result[pairing.formKey] = input[pairing.queryKey]
    }

    if (pairing.type === 'number' && input[pairing.queryKey].match(/[\d]/)) {
      result[pairing.formKey] = parseInt(input[pairing.queryKey])
    }

    if (pairing.type === 'boolean') {
      const bool = Boolean(parseInt(input[pairing.queryKey]))
      result[pairing.formKey] = bool ? 1 : 0
    }
  })

  Object.entries(input).forEach(([key, value]) => {
    if (key.startsWith('p[') && key.endsWith(']')) {
      const index = parseInt(key.replace('p[', '').replace(']', ''))
      result.p = result.p || []
      result.p[index] = value
    }
  })

  return result
}

const toQuery = (input, pairings) => {
  const result = {}

  pairings.forEach((pairing) => {
    if (isNil(input[pairing.formKey])) return

    if (pairing.type === 'string') result[pairing.queryKey] = input[pairing.formKey]

    if (pairing.type === 'date' && input[pairing.formKey].match(dateRegex)) {
      result[pairing.queryKey] = input[pairing.formKey]
    }

    if (pairing.type === 'number' && String(input[pairing.formKey]).match(/[\d]/)) {
      result[pairing.queryKey] = String(input[pairing.formKey])
    }

    if (pairing.type === 'boolean') result[pairing.queryKey] = input[pairing.formKey] ? '1' : '0'
  })

  input.p.forEach((value, index) => {
    const key = ['p[', index, ']'].join('')
    result[key] = value
  })

  return result
}

export default { toApi, toQuery }
