
import analytics from '@/helpers/analytics'

export default {
  computed: {
    phone () {
      return this.$store.getters.phone
    }
  },
  methods: {
    clickPhone (event) {
      analytics.track('phone-click', this)
      window.top.location = `tel:${this.phone}`
    }
  }
}
