
import omit from 'lodash/omit'
import { addDays, format, parseISO, differenceInDays } from 'date-fns'
import isFacebookInAppBrowser from 'detect-facebook-in-app-browser'
import store from 'store'
import analytics from '@/helpers/analytics'
import transformParams from '@/helpers/transformParams'
import insertSojernTag from '@/helpers/insertSojernTag'
import fireFacebookEvents from '@/helpers/fireFacebookEvents'
import { hotels as pairings } from '@/static/data/pairings.json'

export default {
  data () {
    return {
      debug: true,
      destinationData: {},
      destinationError: null,
      form: {
        destination: null,
        destinationText: null,
        startDate: null,
        endDate: null,
        numOfRooms: 1,
        numOfGuests: 1,
        destinationId: null,
        cityName: null,
        p: []
      },
      guestsOptions: [1, 2, 3, 4, 5, 6],
      roomsOptions: [1, 2, 3, 4, 5, 6]
    }
  },
  computed: {
    siteType () {
      return this.$store.state.siteType
    },
    pageTitle () {
      return this.$store.state.pageTitle
    },
    deviceCategory () {
      return this.$store.getters.deviceCategory
    },
    fromPlaceholder () {
      return this.$t(`${this.siteType}.search.${this.deviceCategory}FromPlaceholder`)
    },
    toPlaceholder () {
      return this.$t(`${this.siteType}.search.${this.deviceCategory}ToPlaceholder`)
    }
  },
  created () {
    this.setDefaults()
  },
  methods: {
    openNewsletterModal () {
      this.$store.commit('setNewsletterModal', true)
    },
    selectDestination ({ keyword, data }) {
      this.form.destination = keyword
      this.form.destinationText = keyword
      this.form.destinationId = data.id
      this.form.cityName = data.cityname
      this.destinationData = Object.assign({}, data)
      this.destinationError = false
    },
    setDefaults (dates) {
      const defaults = store.get('HOTELS_LAST_SEARCH')
      const query = this.$route.query || {}

      this.form = Object.assign({}, this.form, defaults || {}, transformParams.toApi(query, pairings))

      const today = new Date()
      if (!this.form.startDate || parseISO(this.form.startDate) < today) this.form.startDate = format(addDays(today, 7), 'yyyy-MM-dd')
      if (!this.form.endDate || parseISO(this.form.endDate) < today) this.form.endDate = format(addDays(today, 14), 'yyyy-MM-dd')
    },
    setStartDate (value) {
      this.form.startDate = value
    },
    setEndDate (value) {
      this.form.endDate = value
    },
    async search () {
      this.destinationError = !Object.keys(this.destinationData).length ? this.$t('search.errors.required') : null
      if (this.destinationError) return

      if (parseISO(this.form.startDate) > parseISO(this.form.endDate)) {
        return this.$toast.error(this.$t('hotels.search.dateError'), { position: 'top-center', duration: 4000 })
      }

      analytics.track('search-submit', this, {
        ...this.form,
        siteType: this.siteType
      })

      try {
        if (!isFacebookInAppBrowser()) window.newTab = window.open('/loading', '_blank')
      } catch (e) {
        console.info('Can not open a new tab')
      }

      store.set('HOTELS_LAST_SEARCH', omit(this.form, ['p', 'gclid', 'msclkid', 'utm_source', 'utm_campaign', 'utm_term']))

      const { query } = this.$route
      const { country, site, entryId } = this.$store.state
      const { deviceCategory } = this.$store.getters

      if (this.$gtag) {
        this.$gtag.event('view_search_results', {
          send_to: this.$config.GOOGLE_REMARKETING,
          user_id: this.$store.state.entryId,
          items: [{
            id: this.destinationData.cityname,
            start_date: this.form.outboundDate,
            end_date: this.form.inboundDate,
            google_business_vertical: 'hotel_rental'
          }]
        })
      }

      // Facebook
      fireFacebookEvents('hotel', this.form, this.originData, this.destinationData)

      // Adara
      const hotelCountry = this.destinationData.countrycode === 'US' ? this.destinationData.countryname.split(',')[1].trim() : this.destinationData.countryname
      const hotelState = this.destinationData.countrycode === 'US' ? this.destinationData.countryname.split(',')[0].trim() : ''

      try {
        const adaraParams = {
          travelProductType: 'Hotel',
          checkinDate: this.form.startDate,
          checkoutDate: this.form.endDate,
          hotelCity: this.destinationData.cityname,
          hotelCountry,
          hotelState,
          numRooms: this.form.numOfRooms,
          numGuest: this.form.numOfGuests
        }

        window.adara('send', {
          pageType: 'Results Page',
          siteLanguage: this.$store.state.language,
          siteCountry: this.$store.state.country,
          ...adaraParams
        })
      } catch (e) {
        console.error(e)
        console.info('Error firing Adara event')
      }

      // Sojern
      try {
        const sojernParams = {
          p: 'search',
          eml: this.$store.state.user.data.email,
          ccid: this.$store.state.entryId,
          tad: this.form.numOfGuests,
          tch: null,
          t: this.form.numOfGuests,
          ppot: 'leisure',
          l: window.navigator.language,
          vid: 'hotel',
          fow: null,
          hc: 'suite',
          hd: differenceInDays(new Date(this.form.endDate), new Date(this.form.startDate)),
          pname: window.location.href,
          hd1: this.form.startDate,
          hd2: this.form.endDate,
          hc1: this.form.cityName,
          hr: this.form.numOfRooms
        }

        insertSojernTag(sojernParams, this)
      } catch (e) {
        console.error(e)
        console.info('Error when inserting the Sojern script')
      }

      try {
        const params = {
          ...this.form,
          device: deviceCategory,
          country: country || 'WW',
          site: site.replace('v2.', '').replace('new.', ''),
          entryId,
          adid: query.gclid || query.msclkid,
          sitebrand: this.siteType
        }

        const deeplinks = await this.$axios.$get('hotels/deeplinks', { params })

        if (!deeplinks.length) {
          return this.$toast.error('No results, please try again', { position: 'top-center', duration: 4000 })
        }

        if (window.newTab) {
          window.newTab.location.href = deeplinks[0].redirect
          if (deeplinks[1]) window.location.href = deeplinks[1].redirect
        } else {
          window.location.href = deeplinks[0].redirect
        }

        if (deeplinks[2] && window.popUnder) {
          window.popUnder.location.href = deeplinks[2].redirect
          window.popUnder.moveTo(0, 0)
          window.popUnder.resizeTo(screen.width, screen.height)
        }
      } catch (e) {
        console.error(e)
        return this.$toast.error(e.toString(), { position: 'top-center', duration: 4000 })
      }
    }
  }
}
