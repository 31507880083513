
export default {
  props: {
    debugData: {
      type: Object,
      default: null
    }
  },
  computed: {
    debugUrl() {
      return this.debugData !== null ? this.debugData.debugUrl : ''
    },
    otaSearchId() {
      return this.debugData !== null ? this.debugData.otaSearchId : ''
    },
    shouldShowDebug() {
      return this.isDebugEnabled
    },
    isDebugEnabled() {
      return this.$store.state.isDebugEnabled
    }
  }
}
