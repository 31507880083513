
import vClickOutside from 'v-click-outside'

export default {
  directives: {
    clickOutside: vClickOutside.directive
  },
  methods: {
    close () {
      this.$emit('close')
    }
  }
}
