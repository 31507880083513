
import isEqual from 'lodash/isEqual'
import orderBy from 'lodash/orderBy'
import minutesToHoursString from '@/helpers/minutesToHoursString'

export default {
  data() {
    return {
      showMaxAirlines: 5,
      isOpen: false,
      submitted: false,
      selectedStops: [],
      selectedOutboundDuration: 0,
      selectedInboundDuration: 0,
      rejectedAirlines: [],
      isAirlinesCollapsed: true
    };
  },
  computed: {
    searchAirlines () {
      return this.$store.state.searchAirlines
    },
    searchAirlinesMain () {
      return this.searchAirlines.filter(item => item.code !== '00')
    },
    searchAirlinesMultiple () {
      return this.searchAirlines.filter(item => item.code === '00')
    },
    searchAirlinesGroups () {
      return [this.searchAirlinesMain, this.searchAirlinesMultiple]
    },
    parsedFilteredResults () {
      return this.$store.getters.parsedFilteredResults
    },
    allowFilter () {
      return (
        !isEqual(this.selectedStops, this.filters.stopOptions) ||
        this.selectedOutboundDuration !== this.filters.maxOutboundDuration ||
        this.selectedInboundDuration !== this.filters.maxInboundDuration ||
        this.rejectedAirlines.length !== this.searchAirlines.length
      )
    },
    results () {
      const results = this.$store.state.searchResults
      if (!results.length) return []
      return results
    },
    filters () {
      const OutboundDurations = this.results.map(flight => flight.legs[0]?.duration)
      const InboundDurations = this.results.map(flight => flight.legs[1]?.duration)

      const minOutboundDuration = Math.min(...OutboundDurations)
      const maxOutboundDuration = Math.max(...OutboundDurations)
      const minInboundDuration = Math.min(...InboundDurations)
      const maxInboundDuration = Math.max(...InboundDurations)

      const stops = this.results.map((flight) => flight.stops)
      const stopOptions = Array.from(new Set(stops))

      return {
        minOutboundDuration,
        maxOutboundDuration,
        minInboundDuration,
        maxInboundDuration,
        stopOptions
      }
    },
    isRoundtrip () {
      // return this.results[0].legs.length === 2
      return this.results.every(flight => flight.legs && flight.legs.length > 1)
    }
  },
  watch: {
    filters: {
      deep: true,
      handler: 'selectDefaults'
    }
  },
  methods: {
    minutesToHoursString,
    scrollTo (id) {
      const el = document.getElementById(id)
      if (el) el.scrollIntoView({ behavior: 'smooth' })
    },
    submit () {
      if (!this.allowFilter) return

      this.submitted = true

      setTimeout(() => {
        this.scrollTo('results')
      }, 500)

      this.$store.commit('setSearchFilters', {
        stops: this.selectedStops,
        outboundDuration: this.selectedOutboundDuration,
        inboundDuration: this.selectedInboundDuration,
        rejectedAirlines: this.rejectedAirlines.slice()
      })
    },
    selectDefaults () {
      this.selectedStops = this.filters.stopOptions
      this.selectedOutboundDuration = this.filters.maxOutboundDuration
      this.selectedInboundDuration = this.filters.maxInboundDuration
      this.rejectedAirlines = this.rejectedAirlines.slice()
    },
    clearFilters () {
      this.submitted = false
      this.$store.commit('clearSearchFilters')
      this.selectDefaults()
      this.airlinesSelectAll()
    },
    toggleAirline (e) {
      const code = e.target.value
      if (e.target.checked) {
        const index = this.rejectedAirlines.indexOf(code)
        if (index !== -1) this.rejectedAirlines.splice(index, 1)
      } else {
        this.rejectedAirlines.push(code)
      }
      const checked = e.target.checked
      this.setSearchAirlineChecked(code, checked)
    },
    airlinesSelectAll () {
      this.rejectedAirlines = []

      this.searchAirlines.forEach((airline) => {
        this.setSearchAirlineChecked(airline.code, true)
      })

      const checkboxes = this.$refs.airline

      if (Array.isArray(checkboxes)) {
        checkboxes.forEach(checkbox => {
          checkbox.checked = true
        })
      } else {
        checkboxes.checked = true
      }
    },
    airlinesClearAll () {
      this.rejectedAirlines = this.searchAirlines.map(item => item.code)

      this.searchAirlines.forEach((airline) => {
        this.setSearchAirlineChecked(airline.code, false)
      })
      this.$store.commit('setClearAllAirlinesEnabled', true)

      const checkboxes = this.$refs.airline

      if (Array.isArray(checkboxes)) {
        checkboxes.forEach(checkbox => {
          checkbox.checked = false
        })
      } else {
        checkboxes.checked = false
      }
    },
    setSearchAirlineChecked (code, checked) {
      this.$store.commit('setSearchAirlineChecked', { code, checked })
    }
  }
}
