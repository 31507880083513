
import get from 'lodash/get'
import padStart from 'lodash/padStart'
import omit from 'lodash/omit'
import first from 'lodash/first'
import last from 'lodash/last'
import { addDays, format, parseISO, differenceInDays } from 'date-fns'
import isFacebookInAppBrowser from 'detect-facebook-in-app-browser'
import store from 'store'
import analytics from '@/helpers/analytics'
import insertSojernTag from '@/helpers/insertSojernTag'
import transformParams from '@/helpers/transformParams'
import fireFacebookEvents from '@/helpers/fireFacebookEvents'
import { cars as pairings } from '@/static/data/pairings.json'
import dayHours from '@/static/data/day_hours.json'

function queryHasArray (query, queryStringKey) {
  return Boolean(Object.entries(query).filter(([key, value]) => {
    return key.startsWith(`${queryStringKey}[`)
  }).length)
}

export default {
  data () {
    return {
      debug: true,
      mountedTime: null,
      originData: {},
      originError: null,
      destinationData: {},
      destinationError: null,
      timeOptions: dayHours.map((item) => {
        let label = [item.standard_format, item.time_of_day].join(' ').toUpperCase()
        if (item.military_format === '0000') label = 'Midnight'
        if (item.military_format === '1200') label = 'Noon'

        return {
          label,
          value: [
            padStart(item.standard_format, 5, 0),
            item.time_of_day === 'md' ? item.extra : item.time_of_day
          ].join(' ').toUpperCase()
        }
      }),
      defaultPickupTime: '12:00 PM',
      defaultDropoffTime: '12:00 PM',
      form: {
        isDroppedInOrigin: true,
        origin: null,
        destination: null,
        originId: null,
        destinationId: null,
        originText: null,
        destinationText: null,
        pickupTime: null,
        dropoffTime: null,
        outboundDate: null,
        inboundDate: null,
        p: []
      }
    }
  },
  computed: {
    showSearchResults () {
      const { query } = this.$route
      const regex = /(adwords|bing|facebook|fb|prpl|ma_)/gi

      if (
        get(query, 'gclid') ||
        get(query, 'msclkid') ||
        queryHasArray(query, 'p') ||
        (get(query, 'utm_source') && get(query, 'utm_source').match(regex))
      ) {
        return false
      }

      return true
    },
    siteType () {
      return this.$store.state.siteType
    },
    pageTitle () {
      return this.$store.state.pageTitle
    },
    formTitle () {
      const { query } = this.$route
      if (query.carType) return this.$t('cars.hero.titleCarType').replace('[value]', query.carType)
      if (query.pickupCity) return this.$t('cars.hero.titlePickupCity').replace('[value]', query.pickupCity)
      return null
    },
    deviceCategory () {
      return this.$store.getters.deviceCategory
    },
    fromPlaceholder () {
      return this.$t(`${this.siteType}.search.${this.deviceCategory}FromPlaceholder`)
    },
    toPlaceholder () {
      return this.$t(`${this.siteType}.search.${this.deviceCategory}ToPlaceholder`)
    }
  },
  created () {
    this.setDefaults()
  },
  mounted () {
    this.mountedTime = new Date().getTime()
  },
  methods: {
    openNewsletterModal () {
      this.$store.commit('setNewsletterModal', true)
    },
    selectOrigin ({ value, keyword, data }) {
      this.originData = Object.assign({}, data)
      if (this.form.isDroppedInOrigin) this.destinationData = data
      this.form.origin = value
      this.form.originText = keyword
      this.form.originId = data.id
      this.originError = null
    },
    selectDestination ({ value, keyword, data }) {
      this.destinationData = Object.assign({}, data)
      this.form.destination = value
      this.form.destinationText = keyword
      this.form.destinationId = data.id
      this.destinationError = null
    },
    setDefaults (dates) {
      const defaults = store.get('CARS_LAST_SEARCH')
      const query = this.$route.query || {}

      this.form = Object.assign({}, this.form, defaults || {}, transformParams.toApi(query, pairings))

      if (
        (this.form.destinationText && (this.form.destinationText !== this.form.originText)) ||
        (this.form.destination && (this.form.destination !== this.form.origin))
      ) this.form.isDroppedInOrigin = false

      if (this.timeOptions.find(option => option.value == this.form.pickupTime) === undefined)
        this.form.pickupTime = this.defaultPickupTime

      if (this.timeOptions.find(option => option.value == this.form.dropoffTime) === undefined)
        this.form.dropoffTime = this.defaultDropoffTime

      const today = new Date()
      if (!this.form.outboundDate || parseISO(this.form.outboundDate) < today) this.form.outboundDate = format(addDays(today, 7), 'yyyy-MM-dd')
      if (!this.form.inboundDate || parseISO(this.form.inboundDate) < today) this.form.inboundDate = format(addDays(today, 14), 'yyyy-MM-dd')
    },
    setOutboundDate (value) {
      this.form.outboundDate = value
    },
    setInboundDate (value) {
      this.form.inboundDate = value
    },
    handleDroppedChange () {
      if (!this.form.isDroppedInOrigin) {
        this.form.destination = null
        this.form.destinationText = null
        this.$nextTick(() => {
          this.$refs.destination.focus()
        })
      }
    },
    scrollTo (id) {
      const el = document.getElementById(id)
      if (el) el.scrollIntoView({ behavior: 'smooth' })
    },
    async search () {
      this.originError = !Object.keys(this.originData).length ? this.$t('search.errors.required') : null
      this.destinationError = !this.form.isDroppedInOrigin && !Object.keys(this.destinationData).length ? this.$t('search.errors.required') : null
      if (this.originError || this.destinationError) return

      if (parseISO(this.form.outboundDate) > parseISO(this.form.inboundDate)) {
        return this.$toast.error(this.$t('cars.search.dateError'), { position: 'top-center', duration: 4000 })
      }

      setTimeout(() => {
        this.scrollTo('car-results')
      }, 500)

      analytics.track('car-search-submit', this, {
        ...this.form,
        siteType: this.siteType
      })

      try {
        if (!this.showSearchResults && !isFacebookInAppBrowser()) window.newTab = window.open('/loading', '_blank')
      } catch (e) {
        console.error(e)
        console.info('Can not open a new tab')
      }

      if (this.form.isDroppedInOrigin) {
        this.form.destination = this.form.origin
        this.form.destinationText = this.form.originText
      }

      store.set('CARS_LAST_SEARCH', omit(this.form, ['p', 'gclid', 'msclkid', 'utm_source', 'utm_campaign', 'utm_term', 'isDroppedInOrigin']))

      const { query } = this.$route
      const { country, site, entryId, browser } = this.$store.state
      const { deviceCategory } = this.$store.getters

      // GTAG
      try {
        if (this.$gtag && this.destinationData) {
          this.$gtag.event('view_search_results', {
            send_to: this.$config.GOOGLE_REMARKETING,
            user_id: this.$store.state.entryId,
            items: [{
              origin: this.destinationData.cityname,
              destination: this.destinationData.cityname,
              start_date: this.form.outboundDate,
              end_date: this.form.inboundDate,
              flight_originid: this.destinationData.cityname,
              flight_destid: this.destinationData.cityname,
              flight_startdate: this.form.outboundDate,
              flights_enddate: this.form.inboundDate,
              flight_pagetype: 'search_results',
              travel_originid: this.destinationData.cityname,
              travel_destid: this.destinationData.cityname,
              travel_startdate: this.form.outboundDate,
              travel_enddate: this.form.inboundDate,
              travel_pagetype: 'search_results',
              dynx_itemid: this.destinationData.cityname,
              dynx_itemid2: this.destinationData.cityname,
              dynx_pagetype: 'search_results',
              google_business_vertical: 'flights'
            }]
          })
        }
      } catch (e) {
        console.error(e)
        console.info('Error firing GTAG event view_search_results')
      }

      // Facebook
      fireFacebookEvents('flight', this.form, this.originData, this.destinationData)

      // Adara
      const collectionPointCountry = this.originData.countrycode === 'US' ? this.originData.countryname.split(',')[1].trim() : this.originData.countryname
      const collectionPointState = this.originData.countrycode === 'US' ? this.originData.countryname.split(',')[0].trim() : ''
      const returnPointCountry = this.destinationData.countrycode === 'US' ? this.destinationData.countryname.split(',')[1].trim() : this.destinationData.countryname
      const returnPointState = this.destinationData.countrycode === 'US' ? this.destinationData.countryname.split(',')[0].trim() : ''

      try {
        const adaraParams = {
          collectionPointCountry,
          collectionPointState,
          returnPointCountry,
          returnPointState,
          travelProductType: 'Car',
          collectionPointCity: this.originData.cityname,
          collectionDate: this.form.outboundDate,
          returnPointCity: this.destinationData.cityname,
          returnDate: this.form.inboundDate,
          startTime: this.form.pickupTime,
          returnTime: this.form.dropoffTime
        }

        window.adara('send', {
          pageType: 'Results Page',
          siteLanguage: this.$store.state.language,
          siteCountry: this.$store.state.country,
          ...adaraParams
        })
      } catch (e) {
        console.error(e)
        console.info('Error firing Adara event')
      }

      // Redirect to car results page
      if (this.showSearchResults) {
        return this.$router.push({ name: 'car-results', query: transformParams.toQuery(this.form, pairings) })
      }


      // Sojern
      try {
        const originCity = this.originData.cityname.split(', ')
        const destinationCity = this.destinationData.cityname.split(', ')
        const sojernParams = {
          p: 'search',
          eml: this.$store.state.user.data.email,
          ccid: this.$store.state.entryId,
          tad: null,
          tch: null,
          t: null,
          ppot: 'leisure',
          l: window.navigator.language,
          vid: 'car',
          fow: null,
          hc: null,
          cd: differenceInDays(new Date(this.form.inboundDate), new Date(this.form.outboundDate)),
          pname: window.location.href,
          rc1: first(originCity),
          rs2: last(originCity),
          rc2: first(destinationCity),
          rs1: last(destinationCity),
          ra1: this.originData.airportcode,
          rd1: this.form.outboundDate,
          rd2: this.form.inboundDate,
          rn1: null,
          rn2: null
        }

        insertSojernTag(sojernParams, this)
      } catch (e) {
        console.error(e)
        console.info('Error when inserting the Sojern script')
      }

      try {
        const params = {
          ...omit(this.form, ['isDroppedInOrigin', 'originText', 'destinationText']),
          device: deviceCategory,
          country: country || 'WW',
          site: site.replace('v2.', '').replace('new.', ''),
          entryId,
          adid: query.gclid || query.msclkid,
          sitebrand: this.siteType
        }

        const deeplinks = await this.$axios.$get(this.$config.API_URL + '/car-rentals/deeplinks', { params })

        if (!deeplinks.length) {
          return this.$toast.error('No results, please try again', { position: 'top-center', duration: 4000 })
        }

        if (window.newTab) {
          window.newTab.location.href = deeplinks[0].redirect
          if (deeplinks[1]) window.location.href = deeplinks[1].redirect
        } else {
          window.location.href = deeplinks[0].redirect
        }

        if (deeplinks[2] && window.popUnder && browser?.name !== 'safari') {
          window.popUnder.location.href = deeplinks[2].redirect
          window.popUnder.moveTo(0, 0)
          window.popUnder.resizeTo(screen.width, screen.height)
        }
      } catch (e) {
        console.error(e)
        return this.$toast.error(e.toString(), { position: 'top-center', duration: 4000 })
      }
    }
  }
}
