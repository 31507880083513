
import { mixin as clickaway } from 'vue-clickaway2'
import analytics from '@/helpers/analytics'

export default {
  mixins: [clickaway],
  data () {
    return {
      isOpen: false,
      currency: 'USD'
    }
  },
  computed: {
    hasLocalCurrency () {
      const results = this.$store.state.searchResults
      return results.length > 0 && results[0].currency !== results[0].currencyLocal
    },
    currencies () {
      const results = this.$store.state.searchResults
      return this.hasLocalCurrency
        ? {
            default: 'USD',
            local: results[0].currencyLocal
          }
        : false
    }
  },
  methods: {
    clickCurrencySelector () {
      analytics.track('currency-bar-click', this)
    },
    toggle () {
      this.isOpen = !this.isOpen
    },
    close () {
      this.isOpen = false
    },
    changeCurrency (keyCurrencies) {
      this.currency = this.currencies[keyCurrencies]
      this.$store.commit('setLocalCurrency', keyCurrencies === 'local')
      this.close()
    }
  }
}
