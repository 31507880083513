
import { mixin as clickaway } from 'vue-clickaway2'
import languages from '@/static/data/languages.json'
import analytics from '@/helpers/analytics'

export default {
  mixins: [clickaway],
  data () {
    return {
      isOpen: false,
      languages
    }
  },
  computed: {
    selectedLanguageName () {
      return this.selectedLanguage !== '' ? this.selectedLanguage.name : ''
    },
    selectedLanguageCode () {
      return this.$store.state.language
    },
    selectedLanguage () {
      return this.languages[this.selectedLanguageCode] ?? ''
    }
  },
  methods: {
    clickLanguageSelector () {
      analytics.track('language-bar-click', this)
    },
    toggle () {
      this.isOpen = !this.isOpen
    },
    close () {
      this.isOpen = false
    },
    changeLanguage (languageCode) {
      this.$cookies.set('lang', languageCode)
      this.$i18n.locale = languageCode
      this.$store.commit('setLanguage', languageCode)
      this.$store.commit('setRtl', ['he'].includes(languageCode))
      this.close()

      const fullPath = this.$route.fullPath

      // Blog post redirect
      const redirect = this.$store.state.translationRedirects[languageCode]
      if (redirect) return this.$router.push(redirect)

      // Blog index redirect
      if (fullPath.startsWith('/blog')) return this.$router.push(`/blog/${languageCode}`)

      // Static pages redirect
      if (fullPath.startsWith('/contact') || fullPath.startsWith('/about')) return window.location.reload()
    }
  }
}
