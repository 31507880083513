import { render, staticRenderFns } from "./BlogListDefault.vue?vue&type=template&id=95a7e808"
import script from "./BlogListDefault.vue?vue&type=script&lang=js"
export * from "./BlogListDefault.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {BlogHeaderAuthor: require('/home/forge/farescraper.com/components/BlogHeaderAuthor.vue').default,BlogFeaturedPost: require('/home/forge/farescraper.com/components/BlogFeaturedPost.vue').default,BlogPost: require('/home/forge/farescraper.com/components/BlogPost.vue').default,BlogPagination: require('/home/forge/farescraper.com/components/BlogPagination.vue').default})
