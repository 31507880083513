
import getHead from '~/helpers/getHead'
import parseBlogPost from '~/helpers/parseBlogPost'
import loadScript from '@/helpers/loadScript'

export default {
  async asyncData ({ $axios, $config, payload, params, query, route, error, store }) {
    if (payload) return payload

    const { siteType } = store.state

    const blogUrl = $config.BLOG_URL
    const titleBase = `${store.state.siteName} Travel Blog`
    let name = 'articles_category_page'
    let title = titleBase
    let heading = 'Latest Articles'
    const description = 'Travel Blog Articles'
    let author = null
    let requestEndpoint = `${blogUrl}/wp-json/wp/v2/posts`
    const page = parseInt(params.page) || 1
    const requestQuery = {
      _embed: false,
      per_page: parseInt(query.limit) || 7,
      page
    }

    if (route.query.s) {
      heading = `Search results for "${route.query.s}"`
      title = `${titleBase} - ${route.query.s}`
      requestEndpoint = `${blogUrl}/wp-json/wp/v2/search`
      requestQuery.type = 'post'
      requestQuery.subtype = 'post'
      requestQuery.search = route.query.s
    }

    if (params.author) {
      const users = await $axios.$get(`${blogUrl}/wp-json/wp/v2/users?search=${params.author}`)
      author = users[0]
      name = 'articles_author_page'
      heading = author.name
      title = `${titleBase} - Articles by ${heading}`
      requestQuery.author = author.id
    }

    params.category = params.category ?? 'EN'
    params.siteType = params.siteType ?? 'FLIGHTS'
    requestQuery.categories = $config.BLOG.LOCALE[params.category.toUpperCase()]
    requestQuery.tags = $config.BLOG.TAGS[params.siteType.toUpperCase()]

    const response = await $axios.get(requestEndpoint, { params: requestQuery })

    const pagination = {
      total: parseInt(response.headers['x-wp-totalpages']),
      current: page,
      params: route.params,
      query: route.query,
      name
    }

    const postsList = response.data.map(post => parseBlogPost(post, blogUrl))

    return { title, heading, description, posts: postsList, pagination, author }
  },
  data () {
    return {
      posts: null,
      pagination: {},
      title: '',
      heading: '',
      description: '',
      author: false,
      courses: null
    }
  },
  head () {
    const { site } = this.$store.state
    const blogUrl = `${this.$config.APP_ROOT}/blog/`

    return getHead({
      root: `https://${site}`,
      url: this.url,
      title: this.title,
      description: this.description,
      image: '/images/blog/thumbnail.png',
      twitterHandle: null,
      script: [{
        type: 'application/ld+json',
        json: {
          '@context': 'https://schema.org',
          '@graph': [
            {
              '@type': 'WebSite',
              '@id': `${blogUrl}#website`,
              url: blogUrl,
              name: this.title,
              publisher: {
                '@id': `${blogUrl}#organization`
              }
            },
            {
              '@type': 'WebPage',
              '@id': `${blogUrl}#webpage`,
              url: blogUrl,
              inLanguage: 'en-US',
              name: this.title,
              isPartOf: {
                '@id': `${blogUrl}/#website`
              },
              about: {
                '@id': `${blogUrl}/#organization`
              },
              description: this.description
            }
          ]
        }
      }]
    })
  },
  computed: {
    breadcrumbsLabel () {
      return this.author ? this.author.name : null
    },
    url () {
      return this.$route.fullPath
    }
  },
  onMounted () {
    const { siteConfig } = this.$store.state
    if (siteConfig.shareTrips) {
      window.ShareTripsParamData = { type: 'flight' }
      loadScript(`https://chat.sharetrips.com/embed/main.js?site=${siteConfig.shareTrips}`, true)
    }
  }
}
