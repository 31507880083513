import fromPairs from 'lodash/fromPairs'
import VueGtm from '@gtm-support/vue2-gtm'
import Vue from 'vue'

export default function ({ $config, store }) {
  const host = store.state.site

  const gtmIds = $config.GTM_ID

  if (!gtmIds) {
    console.log(`NO GTM for this host (${host})`)
    return
  }

  const ids = fromPairs(gtmIds.split(',').map(item => item.split(':')))

  if (!ids[host]) {
    console.log(`NO GTM for this host (${host})`)
    return
  }

  console.log(`GTM loaded (${ids[host]})`)

  Vue.use(VueGtm, {
    id: ids[host],
    defer: true,
    debug: $config.GTM_DEBUG ?? false
  })
}
