
export default {
  async asyncData ({ $content, route, store }) {
    const languageCode = store.state.language
    const article = await $content(`${languageCode}/${route.name}`).fetch()
    return {
      article
    }
  }
}
