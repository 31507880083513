import get from 'lodash/get'
import fromPairs from 'lodash/fromPairs'
import format from 'date-fns/format'
import parseISO from 'date-fns/parseISO'

export default function (item, blogUrl) {
  const post = get(item, '_embedded.self.0', item)

  const translations = fromPairs(['es', 'en'].map((language) => {
    const slug = get(item, `post_meta.translation_${language}`)
    return [language, slug ? `/blog/${language}/${slug}` : null]
  }))

  let seoImage = get(post, ['yoast_head_json', 'og_image', 0, 'url'])
  if (seoImage) seoImage = seoImage.replace('http:', 'https:')

  return {
    ...post,
    image: seoImage || '/images/blog/placeholder.png',
    link: post.link.replace(blogUrl, '/blog'),
    dateForHumans: format(parseISO(post.date), 'MMMM do yyyy'),
    author: get(post, '_embedded.author.0'),
    tagsList: post.status === 'publish' ? get(post, '_embedded.wp:term.1', []).map(tag => tag.slug) : [],
    translations
  }
}
