
import merge from 'lodash/merge'

export default {
  props: {
    theme: {
      type: String,
      default: 'light'
    },
    pagination: {
      type: Object,
      default: () => ({ total: 1, current: 1, params: {}, query: {} })
    }
  },
  computed: {
    pages () {
      const { total, current } = this.pagination
      return new Array(total).fill(null).map((value, page) => ({
        link: this.createLink(page + 1),
        number: page + 1,
        active: page + 1 === current
      }))
    },
    prev () {
      const { current } = this.pagination
      if (current === 1) return null
      return { link: this.createLink(current - 1) }
    },
    next () {
      const { current, total } = this.pagination
      if (current + 1 > total) return null
      return { link: this.createLink(current + 1) }
    }
  },
  methods: {
    createLink (page) {
      const { name, query, params } = this.pagination
      if (page === 1) {
        return { name, query, params: merge({}, params, { hasPage: null, page: null }) }
      }
      return { name, query, params: merge({}, params, { hasPage: 'page', page }) }
    }
  }
}
