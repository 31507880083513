
export default {
  data() {
    return {
      sortingType: null,
      defaultCurrencySymbol: '$'
    }
  },
  computed: {
    results () {
      const results = this.$store.state.searchResults
      if (!results.length) return []
      return results
    },
    getSortedResults () {
      return this.$store.state.sortResults
    },
    currencySymbol () {
      return this.localCurrency ? this.results[0]?.currencySymbolLocal : this.results[0]?.currencySymbol || this.defaultCurrencySymbol
    },
    localCurrency () {
      return this.$store.state.localCurrency
    },
    minPrice () {
      return this.localCurrency ? 'minPriceLocal' : 'minPrice'
    }
  },
  mounted() {
    this.sortingType = 'recommended'
    this.$store.commit('setSortingType', this.sortingType)
  },
  methods: {
    submit(type) {
      this.sortingType = type
      this.$store.commit('setSortingType', type)
    },
    bestPrice() {
      const { recommended, cheapest, fastest } = this.getSortedResults
      const results = this.results
      return {
        recommended: recommended[0]?.[this.minPrice] ?? results[0]?.[this.minPrice],
        fastest: fastest[0]?.[this.minPrice] ?? results[0]?.[this.minPrice],
        cheapest: cheapest[0]?.[this.minPrice] ?? results[0]?.[this.minPrice]
      }
    },
    avgTime() {
      const { recommended, cheapest, fastest } = this.getSortedResults
      const results = this.results

      return {
        recommended: this.calculateAvgTime(recommended) ?? results[0]?.[this.minPrice],
        fastest: this.calculateAvgTime(fastest) ?? results[0]?.[this.minPrice],
        cheapest: this.calculateAvgTime(cheapest) ?? results[0]?.[this.minPrice]
      }
    },
    calculateAvgTime(sortedResults) {
      if (!sortedResults.length) {
        return
      }
      const durations = sortedResults.map(object => object.duration)
      const avgTime = durations.reduce((sum, duration) => sum + duration, 0) / durations.length

      return Math.floor(avgTime / 60) + 'h ' + Math.floor(avgTime % 60)
    }
  }
}
