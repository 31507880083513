const middleware = {}

middleware['bugsnag'] = require('../middleware/bugsnag.js')
middleware['bugsnag'] = middleware['bugsnag'].default || middleware['bugsnag']

middleware['pageTitle'] = require('../middleware/pageTitle.js')
middleware['pageTitle'] = middleware['pageTitle'].default || middleware['pageTitle']

middleware['redirects'] = require('../middleware/redirects.js')
middleware['redirects'] = middleware['redirects'].default || middleware['redirects']

export default middleware
