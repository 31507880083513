
const track = (event, app, data = {}) => {
  if (app.$gtm === undefined) {
    return
  }

  // Unique id present
  if (data.unique !== undefined) {
    const uniqueKey = event + '-' + data.unique
    // Abort if empty or was executed
    if ((data.unique === '') || app.$store.getters.analyticEvents.includes(uniqueKey)) {
      return
    }
    // Store to avoid duplicate
    app.$store.commit('storeEvent', uniqueKey)
  }

  // Add user_id
  if (app.$store.state.entryId !== undefined) {
    data.user_id = app.$store.state.entryId
  }

  const payload = {
    ...data,
    event,
    event_trigger: 'custom'
  }
  app.$gtm.trackEvent(payload)
}

export default { track }
