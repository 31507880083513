export default function (entryId, params, placementIds) {
  const result = {
    data: {
      pick_up_date: params.outboundDate,
      drop_off_date: params.inboundDate,
      drop_off_location: params.destination,
      pick_up_location: params.origin,
      // pick_up_time: params.pickupTime,
      // drop_off_time: params.dropoffTime,
      // duration: (new Date(params.inboundDate).getTime() - new Date(params.inboundDate).getTime()) / (1000 * 3600 * 24)
    },
    locale: 'en',
    placement_id: placementIds,
    type: 'ad_unit',
    sub_1: entryId,
    version: 17
  }

  console.info('Media Alpha Cars params:')
  console.info(result)

  return result
}
