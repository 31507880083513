export default function ({ route, query, redirect, store }) {
  if (query.preview) {
    return redirect(302, `/blog/${store.state.language}/preview:${query.p}`)
  }

  if (route.fullPath === '/blog/author/vanesa') {
    return redirect(302, `/blog/en/author/vanesa`)
  }

  if (['/blog', '/blog/'].includes(route.fullPath)) {
    return redirect(302, `/blog/${store.state.language}`)
  }
}
