import fromPairs from 'lodash/fromPairs'

/* eslint-disable */
export default ({ app, store, $config }) => {
  const host = store.state.site

  const config = $config.FACEBOOK_PIXEL_ID
  if (!config) {
    console.log('FACEBOOK_PIXEL_ID is not defined')
    return
  }

  const ids = fromPairs(config.split(',').map(item => item.split(':')))
  if (!ids[host]) {
    console.log('FACEBOOK_PIXEL_ID is not defined for this host ' + host)
    return
  }

  const pixelId = ids[host]

  /* Facebook Pixel Code - Start */
  !function(f,b,e,v,n,t,s)
  {if(f.fbq)return;n=f.fbq=function(){n.callMethod?
  n.callMethod.apply(n,arguments):n.queue.push(arguments)};
  if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
  n.queue=[];t=b.createElement(e);t.async=!0;
  t.src=v;s=b.getElementsByTagName(e)[0];
  s.parentNode.insertBefore(t,s)}(window, document,'script',
  'https://connect.facebook.net/en_US/fbevents.js');
  /* Facebook Pixel Code - End */

  console.log({ 'Facebook Pixel': pixelId })
  fbq('init', pixelId)
  app.router.afterEach((to, from) => {
    console.log('Facebook Pixel - Page View')
    fbq('track', 'PageView')
  })
}

