

import loadScript from '@/helpers/loadScript'

export default {
  data () {
    return {}
  },
  mounted () {
    loadScript("//widgets.outbrain.com/outbrain.js", true)
  }
}

