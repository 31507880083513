
import { addDays, addYears, parseISO, max, format } from 'date-fns'
import { VueDatePicker } from '@mathieustan/vue-datepicker'
import '@mathieustan/vue-datepicker/dist/vue-datepicker.min.css'
import analytics from '@/helpers/analytics'

export default {
  components: { VueDatePicker },
  props: {
    eventName: {
      type: String,
      default: ''
    },
    date: {
      type: String,
      default: null
    },
    placeholder: {
      type: String,
      default: ''
    },
    relatedDate: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      visible: false,
      pickerDate: null,
      currentDate: new Date()
    }
  },
  computed: {
    nextDay () {
      return addDays(this.currentDate, 1)
    },
    nextYear () {
      return addYears(this.currentDate, 1)
    },
    nextDayFromRelatedDate () {
      return addDays(parseISO(this.relatedDate), 1)
    },
    minDate () {
      if (!this.relatedDate) return this.nextDay
      return max([this.nextDay, this.nextDayFromRelatedDate])
    },
    maxDate () {
      return this.nextYear
    },
    locale () {
      return { lang: this.$store.state.language }
    },
    dateFormat () {
      return this.locale === 'he' ? 'dd/MM/yyyy' : 'ddd, D MMM'
    }
  },
  watch: {
    relatedDate (relatedDate) {
      if (parseISO(this.pickerDate) < this.nextDayFromRelatedDate) {
        this.pickerDate = format(this.nextDayFromRelatedDate, 'yyyy-MM-dd')
      }
      this.emitChange()
      this.visible = true
    }
  },
  created () {
    this.pickerDate = this.date
  },
  methods: {
    open () {
      this.$refs.picker.showDatePicker()
    },
    emitChange () {
      this.visible = false
      if (this.eventName.length) {
        analytics.track(this.eventName, this, {
          unique: this.pickerDate,
          value: this.pickerDate
        })
      }
      this.$emit('update', this.pickerDate)
    }
  }
}
