
export default {
  computed: {
    text () {
      if (this.siteType === 'flights') return 'Phone Only Flight Deals'
      if (this.siteType === 'cars') return 'Phone Exclusives Rental Deals'
      if (this.siteType === 'hotels') return 'Phone Exclusives Hotel Deals'
    },
    siteType () {
      return this.$store.state.siteType
    },
    country () {
      return this.$store.state.country
    }
  }
}
