
export default {
  props: {
    defaults: {
      type: Object,
      default: () => ({})
    },
    enabled: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      isOpen: false,
      passengers: {
        adults: 1,
        children: 0
      }
    }
  },
  computed: {
    text () {
      const text = []
      Object.entries(this.passengers).forEach((entry) => {
        const quantity = parseInt(entry[1], 10)
        if (quantity) {
          const term = this.$tc(`flights.passengers.templates.${entry[0]}`, quantity)
          text.push(`${quantity} ${term}`)
        }
      })
      return text.join(', ')
    }
  },
  watch: {
    enabled (value) {
      if (!value) this.isOpen = false
    }
  },
  created () {
    this.passengers.adults = this.defaults.adults || 1
    this.passengers.children = this.defaults.children || 0
  },
  methods: {
    toggle () {
      if (!this.enabled) return false
      if (this.isOpen) return this.close()
      return this.open()
    },
    open () {
      this.isOpen = true
    },
    close () {
      this.isOpen = false
    },
    away () {
      if (this.isOpen) {
        this.close()
      }
    },
    update (data) {
      this.passengers[data.name] = data.count
      return this.$emit('update', data)
    }
  }
}
