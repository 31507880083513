import { render, staticRenderFns } from "./loading.vue?vue&type=template&id=29271e86"
var script = {}


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppLoading: require('/home/forge/farescraper.com/components/AppLoading.vue').default})
