
import vClickOutside from 'v-click-outside'
import createPopUnder from '@/helpers/createPopUnder'
import analytics from '@/helpers/analytics'

export default {
  directives: {
    clickOutside: vClickOutside.directive
  },
  data () {
    return {
      submitted: false,
      form: {
        email: null,
        name: null,
        dunhill: false
      }
    }
  },
  computed: {
    site () {
      return this.$store.state.site
    },
    siteType () {
      return this.$store.state.siteType
    },
    icon () {
      return `/images/icons/${this.site}.png`
    },
    showDunhillCheck () {
      return false

      /*
      const { country } = this.$store.state
      return country.toUpperCase() === 'US'
      */
    }
  },
  methods: {
    blurNewsletterInput () {
      analytics.track('newsletter-email-fill', this)
    },
    close () {
      this.$store.commit('setNewsletterModal', false)

      this.triggerCloseEvent()

      if (
        window.popUnder ||
        this.$store.state.device.isMobile ||
        this.$store.state.device.isTablet ||
        this.$store.state.browser.name === 'safari') { return }

      createPopUnder()
    },
    triggerCloseEvent () {
      // Trigger event if form was not submitted
      if (!this.submitted) {
        analytics.track('newsletter-closed-empty', this)
      }
    },
    async submit () {
      const entryId = this.$store.state.entryId
      this.submitted = true
      try {
        const payload = {
          emailaddress: this.form.email,
          name: this.form.name,
          dunhill: this.form.dunhill
        }

        analytics.track('newsletter-submit', this, payload)

        await this.$axios.$post(`newsletter/subscribe/${entryId}`, payload)
        this.$store.commit('user/set', this.form)
        this.close()
      } catch (e) {
        return this.$toast.error('An error has ocurred, please reload and try again', { position: 'top-center', duration: 4000 })
      }

      this.$store.commit('user/set', this.form)
      this.close()

      const sha256 = require('simple-sha256')
      const emailSha256 = await sha256(this.form.email)

      try {
        window.adara('identity', {
          email: emailSha256
        })
      } catch (e) {
        console.error(e)
        console.info('Error firing Adara event')
      }
    }
  }
}
