import fromPairs from 'lodash/fromPairs'
import VueGtag from 'vue-gtag'
import Vue from 'vue'

export default function ({ $config, store }) {
  const host = store.state.site

  const googleRemarketing = store.state.siteConfig.googleRemarketing || null

  if (!googleRemarketing) {
    console.log('Empty google remarketing id')
    return
  }

  console.log(`Google remarketing id: ${googleRemarketing}`)

  Vue.use(VueGtag, {
    config: { id: googleRemarketing }
  })
}
