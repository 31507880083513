
import analytics from '@/helpers/analytics'

export default {
  computed: {
    siteType () {
      return this.$store.state.siteType
    },
    phone () {
      return this.$store.getters.phone
    },
    logo () {
      return `/images/logos/${this.$store.state.site}.png`
    }
  },
  methods: {
    clickPhone (event) {
      analytics.track('phone-click', this)
      window.top.location = `tel:${this.phone}`
    }
  }
}
