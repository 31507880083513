export default function ({ root, url, title, description, image, twitterHandle, link, style, script, language, rtl }) {
  const imageUrl = `${image?.startsWith('http') ? '' : root}${image}`
  const lang = language || 'en'

  return {
    title,
    htmlAttrs: {
      lang,
      dir: rtl ? 'rtl' : 'ltr'
    },
    meta: [
      { hid: 'og:title', property: 'og:title', content: title },
      { hid: 'description', name: 'description', content: description },
      { hid: 'og:description', property: 'og:description', content: description },
      { hid: 'og:url', property: 'og:url', content: `${url?.startsWith('http') ? '' : root}${url}` },
      { hid: 'og:type', property: 'og:type', content: 'website' },
      { hid: 'og:image', property: 'og:image', content: imageUrl },
      { hid: 'twitter:image', name: 'twitter:image', content: imageUrl },
      { hid: 'twitter:card', name: 'twitter:card', content: 'summary_large_image' },
      { hid: 'twitter:site', name: 'twitter:site', content: twitterHandle },
      { hid: 'twitter:creator', name: 'twitter:creator', content: twitterHandle },
      { hid: 'twitter:title', name: 'twitter:title', content: title },
      { hid: 'twitter:description', name: 'twitter:description', content: description }
    ],
    link: link || [],
    style: style || [],
    script: script || [],
    __dangerouslyDisableSanitizers: ['meta']
  }
}
