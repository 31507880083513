
const steps = {
  slides: 4,
  config: {
    slidesToShow: 4,
    dots: false,
    arrows: false,
    draggable: false,
    touchMove: false,
    swipe: false,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          arrows: true,
          dots: false,
          infinite: true,
          speed: 1000,
          slidesToShow: 1,
          slidesToScroll: 1,
          draggable: true,
          touchMove: true,
          swipe: true
        }
      }
    ]
  }
}

export default {
  data () {
    return {
      config: steps.config
    }
  },
  computed: {
    showSteps () {
      return this.$store.state.siteConfig.showSteps
    },
    siteType () {
      return this.$store.state.siteType
    },
    slides () {
      return new Array(steps.slides)
        .fill(null)
        .map((value, index) => ({
          text: this.$t(`${this.siteType}.steps.items.step_${index + 1}`),
          img: `/images/${this.siteType}/step${index + 1}.png`
        }))
    }
  }
}
