
import Axios from 'axios'

const log = (type, name, payload, app) => {

  const axios = Axios.create()
  const baseURL = app.$config.API_URL;

  axios.post(baseURL + `/debugger/${type}/${name}`, payload);
}

export default { log }
