
export default {
  props: {
    name: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number],
      default: 0
    }
  },
  data () {
    return {
      count: 0
    }
  },
  created () {
    this.count = parseInt(this.value, 10)
  },
  methods: {
    down () {
      if (this.count === 0) return false
      if (this.name === 'adults' && this.count === 1) return false
      this.count -= 1
      return this.update()
    },
    up () {
      if (this.count < 8) {
        this.count += 1
        this.update()
      }
    },
    update () {
      this.$emit('update', { name: this.name, count: this.count })
    }
  }
}
