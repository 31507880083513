
export default {
  props: {
    default: {
      type: String,
      default: 'economy'
    },
    enabled: {
      type: Boolean,
      default: true
    }
  },
  data () {
    return {
      isOpen: false,
      selectedCabinClass: 'economy',
      cabinClasses: ['economy', 'business', 'first']
    }
  },
  computed: {
    text () {
      return this.$t(`flights.cabinClasses.${this.selectedCabinClass}`)
    }
  },
  created () {
    this.selectedCabinClass = this.default
  },
  methods: {
    toggle () {
      if (this.isOpen) return this.close()
      return this.open()
    },
    open () {
      this.isOpen = true
    },
    close () {
      this.isOpen = false
      this.$emit('update', this.selectedCabinClass)
    },
    away () {
      if (this.isOpen) {
        this.close()
      }
    }
  }
}
