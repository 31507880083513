import MobileDetect from 'mobile-detect'

export default ({ store }) => {
  const md = new MobileDetect(window.navigator.userAgent)
  const isMobile = md.phone() !== null || md.mobile() === 'UnknownMobile'
  const isTablet = md.tablet() !== null || md.mobile() === 'UnknownTablet'
  const isDesktop = !isMobile && !isTablet

  store.commit('setDeviceType', md.mobile())

  store.commit('setDevice', {
    isMobile,
    isTablet,
    isDesktop
  })
}
