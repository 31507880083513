export const state = () => ({
  data: {}
})

export const mutations = {
  set (state, data) {
    state.data = data
  },
  update (state, data) {
    state.data = { ...state.data, ...data }
  },
  reset (state) {
    state.data = null
  }
}
