export default function (mediums, url, message = '') {
  return mediums.map((medium) => {
    let link
    let label
    let icon

    switch (medium) {
      case 'twitter':
        label = 'Share on Twitter'
        icon = 'IconsTwitter'
        link = encodeURI(`https://twitter.com/intent/tweet?text=${message}&url=${url}`)
        break
      case 'email':
        label = 'Share via Email'
        icon = 'IconsEnvelope'
        link = encodeURI(`mailto:?body=${url}&subject=${message}`)
        break
      case 'linkedin':
        label = 'Share on LinkedIn'
        icon = 'IconsLinkedIn'
        link = encodeURI(`https://www.linkedin.com/sharing/share-offsite/?url=${url}`)
        break
      case 'facebook':
        label = 'Share on Facebook'
        icon = 'IconsFacebook'
        link = encodeURI(`https://www.facebook.com/sharer/sharer.php?u=${url}`)
        break
    }

    return { id: medium, link, label, icon }
  })
}
