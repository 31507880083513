
export default {
  name: 'GoogleAdSense',
  props: {
    client: {
      type: String,
      required: true,
    },
    adSlot: {
      required: true,
    },
    format: {
      type: String,
      default: 'auto',
    },
    responsive: {
      type: Boolean,
      default: true,
    }
  },
  async mounted() {
    this.loadAdSenseScript().then(() => {
      this.isScriptLoaded = true;
      this.renderAd();
    });
  },
  methods: {
    loadAdSenseScript() {
      return new Promise((resolve, reject) => {
        // Check if AdSense script is already loaded
        if (typeof window.adsbygoogle !== 'undefined') {
          resolve();
          return;
        }

        const script = document.createElement('script');
        script.async = true;
        script.src = 'https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js';
        script.onload = () => resolve();
        script.onerror = () => reject(new Error('AdSense script failed to load'));
        document.body.appendChild(script);
      });
    },
    renderAd() { 
      const adsenseContainer = this.$refs.adsenseContainer;
      let adsbygoogle;
      if (adsenseContainer) {
        adsenseContainer.innerHTML = `
          <ins class="adsbygoogle"
              style="display:block"
              data-ad-client="${this.client}"
              data-ad-slot="${this.adSlot}"
              data-ad-format="${this.format}"
              ${this.responsive ? 'data-ad-responsive="true"' : ''}></ins>
        `;
        (adsbygoogle = window.adsbygoogle || []).push({});
      }
    },
  },
};
