
import { format, parseISO } from 'date-fns'
import minutesToHoursString from '@/helpers/minutesToHoursString'

export default {
  props: {
    leg: {
      type: Object,
      default: null
    },
    index: {
      type: Number,
      default: null
    }
  },
  computed: {
    arrivalTimeFormatted () {
      return format(parseISO(this.leg.arrTime), 'p')
    },
    departureTimeFormatted () {
      return format(parseISO(this.leg.depTime), 'p')
    },
    date () {
      return format(parseISO(this.leg.depTime), 'yyyy-MM-dd')
    },
    flightTime () {
      return minutesToHoursString(this.leg.duration)
    },
    label () {
      return this.$t(`flights.result.legType.${this.leg.type}`)
    }
  }
}
