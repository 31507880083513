import Vue from 'vue'
import Bugsnag from '@bugsnag/js'
import BugsnagPluginVue from '@bugsnag/plugin-vue'

export default async function ({ app, store, query, $axios }) {
  Bugsnag.start({
    apiKey: '8e96d4eed8d82870f6c3a9d914d9a04c',
    plugins: [new BugsnagPluginVue()]
  })

  const bugsnagVue = Bugsnag.getPlugin('vue')
  bugsnagVue.installVueErrorHandler(Vue)
}
