import qs from 'qs'

export default function (params, entryId) {
  const result = {
    origin: params.origin,
    destination: params.destination,
    outboundDate: params.outboundDate,
    adults: params.adults ?? 1,
    legs: [
      {
        originAirport: params.origin,
        destinationAirport: params.destination,
        date: params.outboundDate
      }
    ],
    passengers: [
      'adult'
    ],
    entryId
  }

  return qs.stringify(result, { arrayFormat: 'indices' })
}
