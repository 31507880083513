
export default {
  props: {
    author: {
      type: Object,
      default: null
    }
  },
  computed: {
    link () {
      const { slug } = this.author
      return { name: 'articles_author_page', params: { author: slug, category: 'en' } }
    },
    avatar () {
      if (this.author && this.author.avatar_urls && this.author.avatar_urls['96']) return this.author.avatar_urls['96']
      return null
    }
  }
}
