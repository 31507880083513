export const state = () => ({
  list: {}
})

export const mutations = {
  load (state, data) {
    state.list = data
  }
}

export const actions = {
  async index ({ commit }, brand) {
    const data = await this.$axios.$get(this.$config.API_URL + `/phones?brand=${brand}`)
    commit('load', data)
  }
}
