function getDevice () {
  return /iPad/.test(navigator.userAgent) ? 't' : /Mobile|iP(hone|od)|Android|BlackBerry|IEMobile|Silk/.test(navigator.userAgent) ? 'm' : 'd'
}

function isProduction ($store) {
  return $store.state.siteBrand === 'flightsearchdirect' && $store.$config.IS_PRODUCTION
  // return $store.state.siteBrand === 'farescraper'
}

function homeTag ($store) {
  if (!isProduction($store)) return

  window.criteo_q = window.criteo_q || []
  window.criteo_q.push(
    { event: 'setAccount', account: $store.$config.CRITEO_ACCOUNT },
    { event: 'setEmail', email: '' },
    { event: 'setSiteType', type: getDevice() },
    { event: 'viewHome' }
  )
}

function productTag ($store, { customerId, price, productId }) {
  if (!isProduction($store)) return

  window.criteo_q = window.criteo_q || []
  window.criteo_q.push(
    { event: 'setAccount', account: $store.$config.CRITEO_ACCOUNT },
    { event: 'setEmail', email: '', hash_method: 'sha256' },
    { event: 'setEmail', email: '', hash_method: 'md5' },
    { event: 'setSiteType', type: getDevice() },
    { event: 'setCustomerId', id: customerId },
    { event: 'setRetailerVisitorId', id: customerId },
    { event: 'viewItem', item: productId, price }
  )
}

function purchaseTag ($store, { customerId, currency, price, id }) {
  if (!isProduction($store)) return

  window.criteo_q = window.criteo_q || []
  window.criteo_q.push(
    { event: 'setAccount', account: $store.$config.CRITEO_ACCOUNT },
    { event: 'setEmail', email: '', hash_method: 'sha256' },
    { event: 'setEmail', email: '', hash_method: 'md5' },
    { event: 'setSiteType', type: getDevice() },
    { event: 'setCustomerId', id: customerId },
    { event: 'setRetailerVisitorId', id: customerId },
    {
      event: 'trackTransaction',
      id,
      currency,
      item: [
        { id, price, quantity: 1 }
      ]
    }
  )
}

// Export the functions
module.exports = {
  homeTag,
  productTag,
  purchaseTag
}
