import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

import _2ba2ce53 from '../pages/blog/index.vue'
import _5c775fbf from '../pages/car-results.vue'
import _f80d3904 from '../pages/loading.vue'
import _72d3d138 from '../pages/results.vue'
import _b98d21c8 from '../pages/static.vue'
import _5f69e89e from '../pages/blog/page.vue'
import _594eae2f from '../pages/blog/post.vue'
import _ea155ad8 from '../pages/index.vue'

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/blog",
    component: _2ba2ce53,
    name: "blog"
  }, {
    path: "/car-results",
    component: _5c775fbf,
    name: "car-results"
  }, {
    path: "/loading",
    component: _f80d3904,
    name: "loading"
  }, {
    path: "/results",
    component: _72d3d138,
    name: "results"
  }, {
    path: "/static",
    component: _b98d21c8,
    name: "static"
  }, {
    path: "/blog/page",
    component: _5f69e89e,
    name: "blog-page"
  }, {
    path: "/blog/post",
    component: _594eae2f,
    name: "blog-post"
  }, {
    path: "/",
    component: _ea155ad8,
    name: "index"
  }, {
    path: "/privacy",
    component: _b98d21c8,
    name: "privacy"
  }, {
    path: "/terms",
    component: _b98d21c8,
    name: "terms"
  }, {
    path: "/about",
    component: _b98d21c8,
    name: "about"
  }, {
    path: "/contact",
    component: _b98d21c8,
    name: "contact"
  }, {
    path: "/flights",
    component: _2ba2ce53,
    name: "articles_index_page"
  }, {
    path: "/flights/:page(\\d+)?",
    component: _2ba2ce53,
    name: "articles_index_page"
  }, {
    path: "/flights/:slug",
    component: _5f69e89e,
    name: "articles_page_page"
  }, {
    path: "/blog/:hasPage(page)?/:page(\\d+)?",
    component: _2ba2ce53,
    name: "articles_index_page"
  }, {
    path: "/blog/:category/:hasPage(page)?/:page(\\d+)?",
    component: _2ba2ce53,
    name: "articles_category_page"
  }, {
    path: "/blog/:category/author/:author/:hasPage(page)?/:page(\\d+)?",
    component: _2ba2ce53,
    name: "articles_author_page"
  }, {
    path: "/blog/:category/:slug?",
    component: _594eae2f,
    name: "articles_post_page"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
