
export default {
  computed: {
    siteType () {
      return this.$store.state.siteType
    },
    backgroundImage () {
      return `url(/images/heroes/${this.$store.state.site}.jpg)`
    }
  }
}
