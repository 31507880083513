import get from 'lodash/get'

export default async function ({ app, store, query, $axios }) {
  const { country, city, siteType, siteName } = store.state

  if (!country && !query.title && !query.toCode) return

  let pageTitle = [siteName]

  if (siteType === 'flights' && country) {
    pageTitle = [app.i18n.t('flights.meta.titleFrom')]
    pageTitle.push(country === 'US' ? city : country)
  }

  /*
  if (query.toCode) {
    const places = await $axios.$get('/airports', { params: { q: query.toCode, locale: 'en' } })
    if (places.length) pageTitle = [app.i18n.t('flights.meta.titleTo'), get(places, '0.cityname')]
  }
*/

  if (query.title) {
    pageTitle = [query.title]
  }

  store.commit('setPageTitle', pageTitle.join(' '))
}
