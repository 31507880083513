
import { parse } from 'node-html-parser'
import parseBlogPost from '~/helpers/parseBlogPost'
import GoogleAdSense from '@/components/GoogleAdSense.vue';
import loadScript from '@/helpers/loadScript'
import qs from 'qs'

export default {
  async asyncData ({ $axios, $config, params, payload, store, error }) {
    if (payload) return payload

    const { siteType, siteName } = store.state

    const blogUrl = $config.BLOG_URL
    const api = `${blogUrl}/wp-json/wp/v2`

    let page

    const pages = await $axios.$get(`${api}/pages?slug=${params.slug}&_embed`)
    if (!pages.length) return error({ statusCode: 404, message: 'Post not found' })
    page = parseBlogPost(pages[0], blogUrl)

    const html = page.content.rendered

    const root = parse(html)

    const headings = root.querySelectorAll('h1, h2, h3')
    if (headings.length) {
      headings[Math.floor(headings.length / 2)].insertAdjacentHTML(
        'beforebegin',
        '<!--<div class="bg-abbey text-white flex items-center justify-center h-[300px]">AD</div>-->'
      )
    }

    const links = root.querySelectorAll('a')
    links.forEach(link => link.setAttribute('target', '_blank'))

    // Find the link element by its text
    const linkElement = root.querySelector(`a:contains("SEARCH")`)
    const relatedSearch = { label: linkElement.text.replace('SEARCH', ''), link: linkElement.getAttribute('href') }
    linkElement.remove()

    const content = root.toString().replace('WEBSITE_BRAND', `<a href="/">${siteName}</a>`)

    const object = qs.parse(relatedSearch.link.replace('/', '').replace('?', ''))
    store.commit('setRelatedSearch', object)
    store.commit('setTranslationRedirects', page.translations)

    return { post: page, content, relatedSearch }
  },
  computed: {
    author () {
      return this.post._embedded.author[0]
    },
    siteType () {
      return this.$store.state.siteType
    },
    adsenseId() {
      return this.$config.ADSENSE_ID ?? null;
    }
  },
  onMounted () {
    const { siteConfig } = this.$store.state
    if (siteConfig.shareTrips) {
      window.ShareTripsParamData = { type: 'flight' }
      loadScript(`https://chat.sharetrips.com/embed/main.js?site=${siteConfig.shareTrips}`, true)
    }
  }
}
