import Vue from 'vue'
import VueI18n from 'vue-i18n'

Vue.use(VueI18n)

export default ({ app, store }) => {
  app.i18n = new VueI18n({
    locale: store.state.language,
    fallbackLocale: 'en',
    messages: {
      it: require('@/static/locales/it.json'),
      de: require('@/static/locales/de.json'),
      fr: require('@/static/locales/fr.json'),
      pt: require('@/static/locales/pt.json'),
      en: require('@/static/locales/en.json'),
      es: require('@/static/locales/es.json'),
      he: require('@/static/locales/he.json')
    }
  })
}
