
export default {
  props: {
    post: {
      type: Object,
      default: null
    },
    showAuthor: {
      type: Boolean,
      default: true
    },
    landscape: {
      type: Boolean,
      default: false
    }
  }
}
