
export default {
  props: {
    backdrop: {
      type: String,
      default: 'bg-alpha-darker'
    }
  },
  data () {
    return {
      lock: true
    }
  },
  mounted () {
    document.addEventListener('keyup', this.escape)
  },
  beforeDestroy () {
    document.removeEventListener('keyup', this.escape)
  },
  methods: {
    close () {
      this.$emit('close')
    },
    escape (e) {
      if (e.keyCode === 27) this.close()
    }
  }
}
