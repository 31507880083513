
export default {
  props: {
    heading: {
      type: String,
      default: ''
    },
    posts: {
      type: Array,
      default: () => ([])
    },
    pagination: {
      type: Object,
      default: null
    },
    author: {
      type: Object,
      default: null
    }
  },
  computed: {
    blogUrl () {
      return this.$config.BLOG_URL
    },
    postsList () {
      return this.posts.slice(1, this.posts.length)
    },
    mainPost () {
      return this.posts[0]
    }
  }
}
