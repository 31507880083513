

export default {
  data() {
    return {
      ad: null,
      placementId: "7e9dd7fa-6e04-4bf0-865a-c67dbd58718e"
    }
  },
  computed: {
    hasAd () {
      return this.ad !== null;
    },
    requestParams () {
      return this.$store.state.adsRequestParams
    }
  },
  watch: {
    requestParams: {
      deep: false,
      handler: 'requestAds'
    }
  },
  methods: {
    async requestAds() {
      if (this.requestParams === null)
        return

      const params = {
        ...this.requestParams,
      }
      const ads = await this.$axios.$get(this.$config.API_URL + `/ads/placement/${this.placementId}`, {params})

      this.ad = ads.partners?.length ? ads.partners[0] : null;
    },
    handleClick() {

      // Google conversion
      if (this.$gtag && this.$store.state.siteConfig.googleConversion) {
        this.$gtag.event('conversion', {
          "send_to": this.$store.state.siteConfig.googleConversion,
          "value": Number(this.ad.bid).toFixed(2),
          "currency": 'USD'
        });
      }

      // Bing conversion
      if (window.uetq) {
        window.uetq.push("event", "purchase", {
          "revenue_value": Number(this.ad.bid).toFixed(2),
          "currency": "USD"
        })
      }

      window.newTab = window.open('/loading', '_blank')
      window.newTab.location.href = this.ad.deeplink
    }
  }
}
