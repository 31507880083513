
import getSharingLinks from '@/helpers/getSharingLinks'
import IconsFacebook from '@/components/IconsFacebook'
import IconsTwitter from '@/components/IconsTwitter'

export default {
  components: {
    IconsFacebook,
    IconsTwitter
  },
  props: {
    post: {
      type: Object,
      default: null
    }
  },
  computed: {
    items () {
      const link = [this.$config.APP_ROOT, this.post.link].join('')
      return getSharingLinks(['twitter', 'facebook'], link, this.post.yoast_head_json.title)
    }
  }
}
