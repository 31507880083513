import template from 'lodash/template'

const regex = /{{(\w+)}}/gm
const subst = '<% if (payload.$1) { %><%= payload.$1 %><% } %>'

function replaceDelimiters (url) {
  return url.replace(regex, subst)
}

function replaceDeeplinksPlaceholder (url, payload) {
  const compiled = template(replaceDelimiters(url))
  return compiled({ payload })
}

export default function replaceDeeplinksPlaceholders (deeplinks = [], payload = {}) {
  return deeplinks.map(deeplink => ({
    ...deeplink,
    redirect: replaceDeeplinksPlaceholder(deeplink.redirect, payload)
  }))
}
