export default function insertRingbaScript (siteConfig = null) {
  if (!siteConfig || !siteConfig.ringba) {
    console.log('Ringba: no config found')
    return
  }

  console.log('Ringba: adding script...')
  const script = document.createElement('script')
  script.async = true
  script.src = siteConfig.ringba
  document.head.appendChild(script)
}
