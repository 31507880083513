
import insertSojernTag from '@/helpers/insertSojernTag'
import criteo from '@/helpers/criteo'
import GoogleAdSense from '@/components/GoogleAdSense.vue'

export default {
  async asyncData ({ $content, store, route }) {
    const siteConfig = store.state.siteConfig
    let article

    if (siteConfig.article) {
      try {
        article = await $content(siteConfig.article).fetch()
      } catch (e) {
        console.error('Article not found')
      }
    }

    return { article }
  },
  data () {
    return {
      theme: null,
      airline: null,
      destCountry: null
    }
  },
  computed: {
    isNewsletterModalOpen () {
      return this.$store.state.isNewsletterModalOpen
    },
    adsenseId () {
      return this.$config.ADSENSE_ID ?? null
    },
    adSlots () {
      return this.$config.ADSENSE_ID ? this.$store.state.siteConfig.adsense : null
    }
  },
  created () {
    this.theme = this.$route.query.theme
    this.airline = this.$route.query.airline
    this.destCountry = this.$route.query.destCountry
  },
  async mounted () {
    insertSojernTag({
      p: 'home',
      ccid: this.$store.state.entryId
    }, this)

    if (!this.$store.state.device.isMobile && !this.$store.state.device.isTablet && !this.$route.query.newslecan) {
      this.openNewsletterModal()
    }

    if (this.$store.state.device.isMobile && this.$route.query.nl) {
      this.openNewsletterModal()
    }

    try {
      window.adara('send', {
        pageType: 'Homepage',
        siteLanguage: this.$store.state.language,
        siteCountry: this.$store.state.country
      })
    } catch (e) {
      console.error(e)
      console.info('Error firing Adara event')
    }

    // Criteo Home page tag
    criteo.homeTag(this.$store)
  },
  methods: {
    openNewsletterModal () {
      this.$store.commit('setNewsletterModal', true)
    }
  }
}
