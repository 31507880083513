import fromPairs from 'lodash/fromPairs'

export default ({ store, query, $config }) => {
  if (query.utm_source && query.utm_source.startsWith('prpl_')) return
  if (store.state.device.isMobile) return

  const host = store.state.site

  const config = $config.PRIMIS_ID
  if (!config) return

  const ids = fromPairs(config.split(',').map(item => item.split(':')))
  if (!ids[host]) return

  (function (d, s, b) {
    let mElmt; const primisElmt = d.createElement('script')
    primisElmt.setAttribute('type', 'text/javascript')
    primisElmt.setAttribute('async', 'async')
    primisElmt.setAttribute('src', s)
    const elmtInterval = setInterval(function () {
      mElmt = d.getElementById(b)
      if (mElmt) {
        mElmt.parentNode.insertBefore(primisElmt, mElmt.nextSibling)
        mElmt.parentNode.removeChild(mElmt)
        return clearInterval(elmtInterval)
      }
    }, 20)
  })(document, `https://live.primis.tech/live/liveView.php?s=${ids[host]}`, 'widgetLoaded')
}
