
import omitBy from 'lodash/omitBy'
import isNil from 'lodash/isNil'
import CarsListItem from '@/components/CarsListItem.vue'
import KayakAdInline from '@/components/KayakAdInline.vue'
// TODO: update analytics key names
import analytics from '@/helpers/analytics'

export default {
  components: {
    CarsListItem,
    KayakAdInline
  },
  props: {
    isLoading: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      topInlineAds: 3,
      altarnateEach: 4,
    }
  },
  computed: {
    firstDeepLink () {
      if (!this.$store.state.deeplinks.length) {
        return ''
      }
      const firstLink = this.$store.state.deeplinks.filter(item => item.provider.toLowerCase() !== 'travelmedia')

      return firstLink[0] ? firstLink[0].redirect : ''
    },
    inlineAds () {
      const ads = this.$store.state.inlineAds
      if (!ads || !ads.length) return [null, null, null]
      return this.parseInlineAds(ads)
    },
    parsedFilteredCarsResults () {
      return this.$store.getters.parsedFilteredCarsResults
    },
    items () {
      const { parsedFilteredCarsResults, inlineAds, topInlineAds, altarnateEach } = this

      // Copy the results to avoid mutation      
      let resultsCopy = [...parsedFilteredCarsResults]
      const items = []

      // Add initial alternating pattern
      for (let i = 0; i < Math.min(resultsCopy.length, inlineAds.length); i++) {
        items.push(inlineAds[i], resultsCopy[i])
      }
      // Add remaining inlineAds if any
      if (inlineAds.length > resultsCopy.length) {
        items.push(...inlineAds.slice(resultsCopy.length))
      }

      // Add subsequent alternating pattern with top inlineAds
      // Limit top inline ads if total inline ads is less than top
      const topAds = Math.min(topInlineAds, inlineAds.length)
      let adsIndex = 0

      for (let i = inlineAds.length + altarnateEach - 2; i < resultsCopy.length; i += altarnateEach) {
        resultsCopy.splice(i, 0, inlineAds[adsIndex])
        adsIndex = adsIndex === topAds - 1 ? 0 : adsIndex + 1
      }
      items.push(...resultsCopy.slice(inlineAds.length))

      return Array.isArray(items) ? omitBy(items, isNil) : []
    },
    hasResultItems () {
      return this.$store.state.searchResults.length > 0
    },
    hasItems () {
      return this.items && Object.values(this.items).length
    }
  },
  watch: {
    hasResultItems (newVal) {
      if (newVal) {
        const firstResult = this.$store.state.searchResults[0]
        this.fireAdaraEvent(firstResult)
      }
    }
  },
  methods: {
    fireAdaraEvent (item) {
      if (Object.keys(item).length === 0) return

      const adaraParams = {
        travelProductType: 'Car',
        rentalCompany: item.agency.name,
        vehicleModel: item.name,
      }
      try {
        window.adara('send', {
          pageType: 'Results Item',
          siteLanguage: this.$store.state.language,
          siteCountry: this.$store.state.country,
          ...adaraParams
        })
      } catch (e) {
        console.info('Error firing Adara event')
      }
    },
    parseInlineAds (ads) {
      return ads.map((ad, index) => ({ ...ad, id: `kayak-${index}`, component: 'KayakAdInline' }))
    },
    handleNoResultsClick () {
      analytics.track('meta-car-no-results-click', this)
    },
    openCompareModal (item) {
      this.$emit('meta-car-openCompareModal', {flight: item})
    },
    compareAll (data) {
      this.$emit('compareAll', data)
    },
  }
}
