import sites from '@/static/data/sites.json'

export default function ({ $gtag, store, $config }) {
  if (process.server) {
    process.env.GOOGLE_REMARKETING = store.state.siteConfig.googleRemarketing || null
  }

  if (!process.server && $config.GOOGLE_REMARKETING) {
    console.log(`Google Remarketing id (${$config.GOOGLE_REMARKETING})`)
  }
}
