import queryString from 'query-string'
import consola from 'consola'
import chalk from 'chalk'

const log = (response) => {
  const { config, status, statusText, data } = response
  const { url, method, params, headers, data: payload } = config

  const linebreak = '\n'
  const statusLabel = chalk[status < 300 ? 'bgGreen' : 'bgRed'].white.bold([status, statusText].join(' '))
  const methodLabel = chalk.white.bold(method.toUpperCase())

  const query = (method === 'get' && params) ? ['?', queryString.stringify(params)].join('') : ''
  consola.log([
    `${statusLabel} ${methodLabel} ${url}${query}`,
    linebreak,
    chalk.blueBright.bold('HEADERS'),
    Object.entries(headers).map(([header, value]) => chalk.bold(header) + ': ' + value).join(linebreak),
    linebreak,
    chalk.blueBright.bold('PAYLOAD'),
    JSON.stringify(method === 'get' ? params : payload, null, 2) || 'None',
    linebreak,
    chalk.blueBright.bold('RESPONSE'),
    // JSON.stringify(data, null, 2),
    linebreak
  ].join(linebreak))
}

export default function ({ $axios, store, env, $config }) {
  const isProduction = $config.environmentName === 'production'
  const enabled = !isProduction

  $axios.defaults.baseURL = '/api'

  const { token } = store.state.user
  if (token) $axios.setToken(token, 'Bearer')

  $axios.onResponse((response) => {
    if (enabled && process.server) log(response)
  })

  $axios.onError((e) => {
    if (enabled && process.server && e.response) log(e.response)
  })
}
