
export default {
  props: {
    label: {
      type: String,
      default: ''
    }
  },
  computed: {
    language () {
      return this.$store.state.language || 'en'
    }
  }
}
