export default function (entryId, params, placementIds) {
  const result = {
    data: {
      add_car: 0,
      add_hotel: params.includeHotel ? 1 : 0,
      flexible_dates: 0,
      flights: [
        {
          origin: params.origin,
          destination: params.destination,
          date: params.outboundDate
        },
        {
          origin: params.destination,
          destination: params.origin,
          date: params.inboundDate
        }
      ],
      num_adults: params.adults,
      num_children: params.children,
      preferred_cabin_class: params.cabinClass
    },
    locale: 'en',
    placement_id: placementIds,
    type: 'ad_unit',
    sub_1: entryId,
    version: 17
  }

  console.info('Media Alpha params:')
  console.info(result)

  return result
}
